import { graphql, Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import '../styles/templates/_judge.scss';

const baseUrl = '/annual-challenge/finalists-mentors-judges';
const Judge = ({ data }) => {
  const judge = data.judge;
  //const mentees = data.mentees.nodes;

  return (
    <Layout
      title={`Judge: ${judge.name}`}
      className="annual-challenge finalists-mentors-judges judge"
    >
      <Section>
        <Container>
          {/* Breadcrumbs */}
          <ul className="breadcrumbs no-hero">
            <li className="breadcrumb">
              <Link to="/annual-challenge/about-the-challenge/">
                Annual Challenge
              </Link>
            </li>
            <li className="breadcrumb">
              <Link to={baseUrl}>Finalists, Mentors and Judges</Link>
            </li>
            <li className="breadcrumb">
              <Link to={`${baseUrl}/judges#innovators-anchor`}>Judges</Link>
            </li>
            <li className="breadcrumb active">
              {judge.prefix} {judge.name}
            </li>
          </ul>
          {/* Judge Prfile */}
          <Row>
            <Column size={7} offset={1}>
              <div className="profile-intro">
                <h3 className="meet-profile">Meet the Judge:</h3>
                <h1>
                  {judge.prefix} {judge.name}
                </h1>
                <h2 className="subtitle">{judge.jobTitle}</h2>
                <h3 className="subtitle">{judge.jobDivision}</h3>
              </div>
              <div className="profile-description">
                {judge.longDescription.map((paragraph, i) => (
                  <p key={i}>{paragraph}</p>
                ))}
              </div>
            </Column>
            <Column size={4}>
              <Quote image={judge.quoteImage} text={judge.shortDescription} />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    judge: judgesYaml(slug: { eq: $slug }) {
      prefix
      name
      jobTitle
      jobDivision
      longDescription
      shortDescription
      quoteImage
    }
  }
`;

export default Judge;
